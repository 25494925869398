import { TableCell, Typography } from '@mui/material'

type TableCellTextProps = {
  text?: string | null
  colSpan?: number
}

export function TableCellText({ text, colSpan }: TableCellTextProps): JSX.Element {
  return (
    <TableCell colSpan={colSpan}>
      <Typography variant="body1">{text || '-'}</Typography>
    </TableCell>
  )
}
